import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./Pages/AuthContext"; // Ensure this path is correct
import AdminLogin from "./Pages/AdminLogin";
import AdminDashboard from "./Pages/AdminDashboard";
import UserDashboard from "./Pages/UserDashboard";
import FinanceDashboard from "./Pages/FinanceDashboard";
import Signup from "./Pages/AdminSignup";
import ProtectedRoute from "./Pages/protectedroute"; // Import the ProtectedRoute components
import UsersManagement from "./components/AdminComp/usermanagement";
import MyProfile from "./components/MyProfile";
import AttendancePage from "./components/UserAttendence";
import UserAttendancePage from "./components/UserAttendence/adminview";



const App: React.FC = () => {
  const { user, role } = useAuth();

  return (
   
      <>

    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/adminsignup" element={<Signup />} />
        <Route path="/myprofile" element={<MyProfile />} />
       
        {/* Redirect logged-in users away from login */}
        <Route
          path="/adminlogin"
          element={user ? <Navigate to={`/${role}dashboard`} replace /> : <AdminLogin />}
        />

        {/* Protected Routes */}
        <Route
          path="/admindashboard"
          element={
            <ProtectedRoute role="admin">
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
         <Route
          path="/userattendance"
          element={
            <ProtectedRoute role="admin">
              <UserAttendancePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/usersmanagement"
          element={
            <ProtectedRoute role="admin">
              <UsersManagement />
            </ProtectedRoute>
          }
          />
        <Route
          path="/userdashboard"
          element={
            <ProtectedRoute role="user">
              <UserDashboard />
            </ProtectedRoute>
          }
        />

<Route
          path="/attendance"
          element={
            <ProtectedRoute role="user">
              <AttendancePage />
            </ProtectedRoute>
          }
        />
 
        <Route
          path="/financedashboard"
          element={
            <ProtectedRoute role="finance">
              <FinanceDashboard />
            </ProtectedRoute>
          }
        />

        {/* Catch-all Route */}
        <Route
          path="*"
          element={
            user ? (
              <Navigate to={`/${role}dashboard`} replace />
            ) : (
              <Navigate to="/adminlogin" replace />
            )
          }
        />
      </Routes>
    </Router>
    </>
  );
};

export default App;
