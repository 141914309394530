import React, { useState, useEffect } from "react";
import { useAuth } from "../../Pages/AuthContext"; // Adjust the path
import { useNavigate } from "react-router-dom"; // For navigation
import { doc, updateDoc, getDoc } from "firebase/firestore"; // Firestore imports
import { getFirestore } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth"; // Firebase Auth imports
import firebaseApp from "../Firebase/firebase"; // Adjust the path to your Firebase config
import DashboardLayout from "../../layouts/dashboardlayout";

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

const MyProfile: React.FC = () => {
  const { user } = useAuth(); // Destructure `user` from AuthContext
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [role, setRole] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false); // State to track if reset email is sent
  const [resetError, setResetError] = useState(""); // State to handle reset errors

  // Fetch user profile data
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setName(userData.name || "");
          setEmail(userData.email || "");
          setContactNumber(userData.contactNumber || "");
          setRole(userData.role || "");
        }
      }
    };

    fetchUserProfile();
  }, [user]);

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (user) {
      await updateDoc(doc(db, "users", user.uid), {
        name,
        email,
        contactNumber,
      });
      alert("Profile updated successfully!");
    }
  };

  // Handle password reset
  const handlePasswordReset = async () => {
    if (!user?.email) {
      setResetError("No email address found for this user.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, user.email);
      setResetEmailSent(true);
      setResetError("");
      alert("Password reset email sent successfully!");
    } catch (error: any) {
      setResetError(error.message);
      console.error("Error sending password reset email:", error);
    }
  };

  return (
    <DashboardLayout title="User Profile" showBackButton>
      <div className="min-h-screen bg-gray-50 font-sans p-6">
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
          <h1 className="text-2xl font-bold mb-6">My Profile</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700">
                Contact Number
              </label>
              <input
                type="text"
                id="contactNumber"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                Role
              </label>
              <input
                type="text"
                id="role"
                value={role}
                readOnly
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm bg-gray-100"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors mb-4"
            >
              Update Profile
            </button>
          </form>

          {/* Password Reset Section */}
          <div className="mt-6">
            <h2 className="text-xl font-bold mb-4">Password Reset</h2>
            <button
              type="button"
              onClick={handlePasswordReset}
              className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
            >
              Send Password Reset Email
            </button>
            {resetEmailSent && (
              <p className="mt-2 text-green-600">Password reset email sent successfully!</p>
            )}
            {resetError && (
              <p className="mt-2 text-red-600">{resetError}</p>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MyProfile;