import React, { useState, useEffect } from "react";
import { useAuth } from "../../Pages/AuthContext"; // Adjust the import path
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { database } from "../Firebase/firebase";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "react-calendar/dist/Calendar.css";
import { format, isToday } from "date-fns";
import DashboardLayout from "../../layouts/dashboardlayout";

interface AuthContextType {
  user: any; // Replace `any` with the actual type of `user`
  userName: string;
  role: string;
}

const AttendancePage = () => {
  const { user, userName, role } = useAuth(); // Use user, userName, and role from AuthContext
  const [currentTime, setCurrentTime] = useState(new Date());
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [workMode, setWorkMode] = useState<"onSite" | "workFromHome">("onSite"); // Default to "On Site"
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [attendance, setAttendance] = useState<
    { date: string; status: string; workMode: "onSite" | "workFromHome"; latitude?: number | null; longitude?: number | null }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  // Fetch user's location
  useEffect(() => {
    if (workMode === "onSite" && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          setError("Unable to retrieve your location.");
          console.error("Geolocation error:", error);
        }
      );
    } else {
      setLatitude(null);
      setLongitude(null);
    }
  }, [workMode]);

  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  // Fetch attendance data for the user
  useEffect(() => {
    const fetchAttendance = async () => {
      if (user) {
        const q = query(
          collection(database, "attendance"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);
        const attendanceData = querySnapshot.docs.map((doc) => ({
          date: doc.data().date,
          status: doc.data().status,
          workMode: doc.data().workMode || "N/A", // Default to "N/A" if workMode is not set
          latitude: doc.data().latitude || null, // Include latitude
          longitude: doc.data().longitude || null, // Include longitude
        }));
        setAttendance(attendanceData);
      }
    };

    fetchAttendance();
  }, [user]);

  // Handle marking attendance
  const handleMarkAttendance = async () => {
    if (workMode === "onSite" && (!latitude || !longitude)) {
      setError("Unable to retrieve your location.");
      return;
    }

    // Check if attendance is already marked for the current day
    const today = format(currentTime, "yyyy-MM-dd");
    const isAttendanceMarked = attendance.some((record) => record.date === today);

    if (isAttendanceMarked) {
      setError("Attendance is already marked for today.");
      return;
    }

    try {
      // Save attendance in Firestore
      await addDoc(collection(database, "attendance"), {
        userId: user?.uid,
        name: userName || "Unknown",
        date: today,
        time: format(currentTime, "hh:mm:ss a"), // 12-hour format
        latitude: workMode === "onSite" ? latitude : null,
        longitude: workMode === "onSite" ? longitude : null,
        status: "present", // Always mark as "present" for both work modes
        workMode, // Include work mode in the Firestore document
      });

      // Update local attendance state
      setAttendance([
        ...attendance,
        {
          date: today,
          status: "present", // Always mark as "present" for both work modes
          workMode,
          latitude,
          longitude,
        },
      ]);

      setSuccess("Attendance marked successfully!");
      setError("");
    } catch (err: any) {
      setError("Failed to mark attendance. Please try again.");
      console.error("Error marking attendance:", err);
    }
  };

  // Handle calendar date change
  const handleDateChange = (value: Value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
    } else if (Array.isArray(value) && value[0] instanceof Date) {
      setSelectedDate(value[0]); // Handle date range (if needed)
    }
  };

  // Get attendance status for a specific date
  const getAttendanceStatus = (date: Date) => {
    const dateString = format(date, "yyyy-MM-dd");
    const record = attendance.find((record) => record.date === dateString);

    if (!record) {
      return "absent";
    }

    return record.workMode === "onSite" ? "On Site" : "Work From Home";
  };

  // Custom calendar tile content
  const tileContent = ({ date }: { date: Date }) => {
    const dateString = format(date, "yyyy-MM-dd");
    const record = attendance.find((record) => record.date === dateString);

    if (!record) {
      return (
        <div
          style={{
            backgroundColor: "red", // Absent
            color: "white",
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {date.getDate()}
        </div>
      );
    }

    let backgroundColor = "green"; // Default for present
    let label = "Present";

    if (record.workMode === "workFromHome") {
      backgroundColor = "blue"; // Work from home
      label = "WFH";
    }

    return (
      <div
        style={{
          backgroundColor,
          color: "white",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {date.getDate()}
      </div>
    );
  };

  return (
    <DashboardLayout title="User Attendance" showBackButton>
      <div className="min-h-screen bg-gray-100 p-6">
        <h1 className="text-2xl font-bold mb-6">Attendance</h1>

        {/* User Information */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">User Information</h2>
          <div className="space-y-2">
            <p className="text-lg">
              <span className="font-semibold">Name:</span> {userName}
            </p>
            <p className="text-lg">
              <span className="font-semibold">Role:</span> {role}
            </p>
          </div>
        </div>

        {/* Work Mode Selection */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Work Mode</h2>
          <div className="space-y-2">
            <label className="flex items-center">
              <input
                type="radio"
                name="workMode"
                value="onSite"
                checked={workMode === "onSite"}
                onChange={() => setWorkMode("onSite")}
                className="mr-2"
              />
              On Site
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="workMode"
                value="workFromHome"
                checked={workMode === "workFromHome"}
                onChange={() => setWorkMode("workFromHome")}
                className="mr-2"
              />
              Work From Home
            </label>
          </div>
        </div>

        {/* Current Time and Location */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Current Details</h2>
          <div className="space-y-2">
            <p className="text-lg">
              <span className="font-semibold">Current Time:</span>{" "}
              {format(currentTime, "hh:mm:ss a")} {/* 12-hour format */}
            </p>
            {workMode === "onSite" && (
              <p className="text-lg">
                <span className="font-semibold">Your Location:</span>{" "}
                {latitude && longitude ? (
                  <>
                    Latitude: {latitude.toFixed(6)}, Longitude: {longitude.toFixed(6)}
                  </>
                ) : (
                  "Fetching location..."
                )}
              </p>
            )}
          </div>
        </div>

        {/* Mark Attendance Button */}
        <div className="mb-6">
          <button
            onClick={handleMarkAttendance}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Mark Attendance
          </button>
        </div>

        {/* Error and Success Messages */}
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded-lg mb-6">
            {error}
          </div>
        )}
        {success && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded-lg mb-6">
            {success}
          </div>
        )}

        {/* Attendance Calendar */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Attendance Calendar</h2>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileContent={tileContent}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AttendancePage;