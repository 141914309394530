import React from "react";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaArrowLeft } from "react-icons/fa"; // Import icons

interface DashboardLayoutProps {
  title: string; // Title of the page
  children: React.ReactNode; // Content of the page
  showBackButton?: boolean; // Optionally show a back button
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  title,
  children,
  showBackButton = false,
}) => {
  const navigate = useNavigate();

  // Handle logout
  const handleLogout = () => {
    // Add your logout logic here
    navigate("/login");
  };

  // Handle back button click
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      {/* Header */}
      <div className="fixed top-0 left-0 w-full bg-white shadow-sm z-10">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {/* Logo */}
            <img src="/images/logo/cprlogo.png" alt="Company Logo" className="h-10" />
            {/* Page Title */}
            <h1 className="text-2xl font-light text-gray-800">{title}</h1>
          </div>

          {/* Back Button (if enabled) */}
          {showBackButton && (
            <button
              onClick={handleBack}
              className="flex items-center bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition-colors"
            >
              <FaArrowLeft className="mr-2" />
              Back
            </button>
          )}

          {/* Logout Button */}
          <button
            onClick={handleLogout}
            className="flex items-center bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
          >
            <FaSignOutAlt className="mr-2" />
            Logout
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 pt-24 pb-8">
        {children} {/* Render the page content */}
      </div>
    </div>
  );
};

export default DashboardLayout;