import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

interface ProtectedRouteProps {
  role: string;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ role, children }) => {
  const { user, role: userRole, loading } = useAuth();

  console.log("ProtectedRoute state:", { user, userRole, loading });

  if (loading) {
    return  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  </div>; // Show a loading indicator while resolving auth state
  }

  if (!user) {
    console.log("No authenticated user, redirecting to login.");
    return <Navigate to="/adminlogin" replace />;
  }

  if (userRole !== role) {
    console.log(`Role mismatch. Redirecting to /${userRole}dashboard...`);
    return <Navigate to={`/${userRole}dashboard`} replace />;
  }

  console.log("Access granted. Rendering protected content.");
  return <>{children}</>;
};

export default ProtectedRoute;
