import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../components/Firebase/firebase"; // Ensure path is correct
import { onAuthStateChanged, signOut, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { database } from "../components/Firebase/firebase"; // Ensure path is correct

interface AuthContextProps {
  user: any;
  userName: string;
  role: string;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  userName: "",
  role: "",
  loading: true,
  login: async () => {},
  logout: async () => {},
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [userName, setUserName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  // Helper to update auth state
  const setAuthState = (user: any, userName: string, role: string) => {
    console.log("Setting auth state:", { user, userName, role });
    setUser(user);
    setUserName(userName);
    setRole(role);
    setLoading(false);
  };

  // Login function
  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      console.log("Attempting to log in...");
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      console.log("Fetching user data from Firestore...");
      const userDocRef = doc(database, "users", userId);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        console.log("User data fetched from Firestore:", userData);
        setAuthState(userCredential.user, userData.name, userData.role);
      } else {
        console.error("User document not found in Firestore.");
        throw new Error("User not found in Firestore.");
      }
    } catch (err) {
      console.error("Login error:", err);
      setLoading(false);
      throw err;
    }
  };

  // Logout function
  const logout = async () => {
    try {
      console.log("Logging out...");
      await signOut(auth);
      setAuthState(null, "", "");
    } catch (err) {
      console.error("Logout error:", err);
      throw err;
    }
  };

  // Listen to auth state changes
  useEffect(() => {
    console.log("Initializing auth state listener...");
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        console.log("User signed in:", currentUser.email);
        const userId = currentUser.uid;

        try {
          console.log("Fetching Firestore data for signed-in user...");
          const userDocRef = doc(database, "users", userId);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            console.log("Fetched user data on auth state change:", userData);
            setAuthState(currentUser, userData.name, userData.role);
          } else {
            console.error("Firestore document not found for user.");
            setAuthState(null, "", "");
          }
        } catch (err) {
          console.error("Error fetching Firestore document:", err);
          setAuthState(null, "", "");
        }
      } else {
        console.log("No user signed in.");
        setAuthState(null, "", "");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, userName, role, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
