import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("admin");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    try {
      await login(email, password); // Log in with email and password
      navigate(`/${role}dashboard`); // Redirect to the selected dashboard
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm w-full max-w-md">
      <div className="p-6 sm:p-8">
        {/* Logo and Heading */}
        <div className="text-center">
          <img src="/images/logo/cprlogo.png" className="h-12 mx-auto mb-4 " />
          <h1 className="text-2xl font-bold text-gray-800 pt-10">Sign in</h1>
          
        </div>

        {/* Login Form */}
        <form onSubmit={handleLogin} className="mt-6">
          <div className="grid gap-y-4">
            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-sm mb-2 text-gray-800">
                Email address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Enter your email"
                required
              />
            </div>

            {/* Password Input */}
            <div>
              <label htmlFor="password" className="block text-sm mb-2 text-gray-800">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Enter your password"
                required
              />
            </div>

            {/* Role Select */}
            <div>
              <label htmlFor="role" className="block text-sm mb-2 text-gray-800">
                Role
              </label>
              <select
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                required
              >
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="finance">Finance</option>
              </select>
            </div>

            {/* Error Message */}
            {error && (
              <div className="text-sm text-red-600 text-center">
                {error}
              </div>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full py-3 px-4 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  );
};

export default AdminLogin;
