import React, { useState, useEffect } from "react";
import { useAuth } from "../../Pages/AuthContext"; // Adjust the import path
import { collection, query, where, onSnapshot, doc, setDoc } from "firebase/firestore";
import { database } from "../Firebase/firebase";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "react-calendar/dist/Calendar.css";
import { format, startOfMonth, endOfMonth, getDaysInMonth } from "date-fns";
import DashboardLayout from "../../layouts/dashboardlayout";
import Modal from "../../layouts/model"; // Import a modal component
import Skeleton from "react-loading-skeleton"; // Import a skeleton loading library
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton CSS

const UserAttendancePage = () => {
  const { user, userName, role } = useAuth(); // Use user, userName, and role from AuthContext
  const [attendance, setAttendance] = useState<{ userId: string; date: string; time: string; status: string; workMode: string; latitude?: number; longitude?: number }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Date | null>(null);
  const [monthlyAttendance, setMonthlyAttendance] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch all users
    const fetchAllUsers = async () => {
      setIsLoading(true); // Start loading
      try {
        const usersQuery = query(collection(database, "users"));
        const unsubscribe = onSnapshot(usersQuery, (querySnapshot) => {
          const usersData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAllUsers(usersData);
          setIsLoading(false); // Stop loading after data is fetched
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching users:", error);
        setIsLoading(false); // Stop loading in case of error
      }
    };

    fetchAllUsers();
  }, []);

  useEffect(() => {
    // Fetch attendance in real-time
    const fetchRealTimeAttendance = async () => {
      setIsLoading(true); // Start loading
      try {
        const q = query(collection(database, "attendance"));
        
        // Use `onSnapshot` for real-time updates
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const attendanceData = querySnapshot.docs.map((doc) => ({
            userId: doc.data().userId,
            date: doc.data().date,
            time: doc.data().time,
            status: doc.data().status,
            workMode: doc.data().workMode || "N/A", // Default to "N/A" if workMode is not set
            latitude: doc.data().latitude || null, // Include latitude
            longitude: doc.data().longitude || null, // Include longitude
          }));
          setAttendance(attendanceData);
          setIsLoading(false); // Stop loading after data is fetched
        });

        // Cleanup subscription on unmount
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching attendance:", error);
        setIsLoading(false); // Stop loading in case of error
      }
    };

    fetchRealTimeAttendance();
  }, []);

  const handleMarkAttendance = async (userId: string, workMode: string) => {
    const today = format(new Date(), "yyyy-MM-dd");
    const time = format(new Date(), "hh:mm:ss a"); // 12-hour format
    const attendanceRef = doc(database, "attendance", `${userId}_${today}`);

    try {
      // Get the user's location if workMode is "onSite"
      let latitude: number | null = null;
      let longitude: number | null = null;

      if (workMode === "onSite") {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      }

      await setDoc(attendanceRef, {
        userId,
        date: today,
        time,
        status: workMode === "onSite" ? "present" : "workFromHome", // Set status based on work mode
        workMode, // Include work mode in the Firestore document
        latitude: workMode === "onSite" ? latitude : null, // Include latitude if on-site
        longitude: workMode === "onSite" ? longitude : null, // Include longitude if on-site
      });
      console.log("Attendance marked as present for:", userId);
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };

  const handleMonthChange = async (value: Value) => {
    if (value instanceof Date) {
      setSelectedMonth(value);
      await fetchMonthlyAttendance(value);
    }
  };

  const fetchMonthlyAttendance = async (date: Date) => {
    setIsLoading(true); // Start loading
    try {
      const startDate = format(startOfMonth(date), "yyyy-MM-dd");
      const endDate = format(endOfMonth(date), "yyyy-MM-dd");

      const q = query(
        collection(database, "attendance"),
        where("date", ">=", startDate),
        where("date", "<=", endDate)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const attendanceData = querySnapshot.docs.map((doc) => ({
          userId: doc.data().userId,
          date: doc.data().date,
          time: doc.data().time,
          status: doc.data().status,
          workMode: doc.data().workMode || "N/A", // Default to "N/A" if workMode is not set
          latitude: doc.data().latitude || null, // Include latitude
          longitude: doc.data().longitude || null, // Include longitude
        }));
        setMonthlyAttendance(attendanceData);
        setIsLoading(false); // Stop loading after data is fetched
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error fetching monthly attendance:", error);
      setIsLoading(false); // Stop loading in case of error
    }
  };

  const getTodayAttendanceStatus = (userId: string) => {
    const today = format(new Date(), "yyyy-MM-dd");
    const todayRecord = attendance.find((record) => record.userId === userId && record.date === today);
    return todayRecord ? todayRecord.status : "absent";
  };

  const getTodayAttendanceTime = (userId: string) => {
    const today = format(new Date(), "yyyy-MM-dd");
    const todayRecord = attendance.find((record) => record.userId === userId && record.date === today);
    return todayRecord ? todayRecord.time : "N/A";
  };

  const getTodayWorkMode = (userId: string) => {
    const today = format(new Date(), "yyyy-MM-dd");
    const todayRecord = attendance.find((record) => record.userId === userId && record.date === today);
    if (todayRecord) {
      if (todayRecord.workMode === "onSite") {
        return (
          <div>
            <p>On Site</p>
            <p>Latitude: {todayRecord.latitude?.toFixed(6)}</p>
            <p>Longitude: {todayRecord.longitude?.toFixed(6)}</p>
          </div>
        );
      } else {
        return "Work From Home";
      }
    }
    return "N/A";
  };

  const getPresentDays = (userId: string) => {
    const currentMonth = format(new Date(), "yyyy-MM");
    return attendance.filter(
      (record) => record.userId === userId && record.status === "present" && record.date.startsWith(currentMonth)
    ).length;
  };

  const getMonthDays = () => {
    const today = new Date();
    return getDaysInMonth(today);
  };

  return (
    <DashboardLayout title="User Attendance" showBackButton>
      <div className="min-h-screen bg-gray-100 p-6">
        <h1 className="text-2xl font-bold mb-6">User Attendance</h1>

        {/* Attendance Table */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Attendance Details</h2>
          {isLoading ? (
            <Skeleton count={5} height={40} /> // Skeleton loading for attendance table
          ) : (
            <table className="w-full table-auto border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">Name</th>
                  <th className="px-4 py-2 border">Role</th>
                  <th className="px-4 py-2 border">Today's Date</th>
                  <th className="px-4 py-2 border">Today's Attendance</th>
                  <th className="px-4 py-2 border">Time Marked</th>
                  <th className="px-4 py-2 border">Work Mode</th>
                  <th className="px-4 py-2 border">Month Days</th>
                  <th className="px-4 py-2 border">Present Days</th>
                  <th className="px-4 py-2 border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="px-4 py-2 border">{user.name}</td>
                    <td className="px-4 py-2 border">{user.role}</td>
                    <td className="px-4 py-2 border">{format(new Date(), "yyyy-MM-dd")}</td>
                    <td
                      className={`px-4 py-2 border ${
                        getTodayAttendanceStatus(user.id) === "present" ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {getTodayAttendanceStatus(user.id) === "present" ? "Present" : "Absent"}
                    </td>
                    <td className="px-4 py-2 border">{getTodayAttendanceTime(user.id)}</td>
                    <td className="px-4 py-2 border">{getTodayWorkMode(user.id)}</td>
                    <td className="px-4 py-2 border">{getMonthDays()}</td>
                    <td className="px-4 py-2 border">{getPresentDays(user.id)}</td>
                    <td className="px-4 py-2 border">
                      <button
                        onClick={() => handleMarkAttendance(user.id, "onSite")} // Default to "onSite"
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                        disabled={getTodayAttendanceStatus(user.id) === "present"}
                      >
                        Mark Attendance
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* View All Attendance Button */}
        <div className="mb-6 flex justify-end">
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            View Attendance Calendar
          </button>
        </div>

        {/* Attendance Calendar Popup */}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl h-[90vh] flex flex-col">
              <div className="p-6 border-b">
                <h2 className="text-xl font-bold mb-4">Attendance Calendar</h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                  &times;
                </button>
              </div>
              <div className="p-6 overflow-y-auto flex-1">
                <Calendar
                  onChange={handleMonthChange}
                  value={selectedMonth || new Date()}
                  view="year"
                  onClickMonth={(value) => handleMonthChange(value)}
                />
                {selectedMonth && (
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold mb-2">Attendance for {format(selectedMonth, "MMMM yyyy")}</h3>
                    {isLoading ? (
                      <Skeleton count={5} height={40} /> // Skeleton loading for monthly attendance
                    ) : (
                      <table className="w-full table-auto border-collapse border border-gray-200">
                        <thead>
                          <tr>
                            <th className="px-4 py-2 border">User</th>
                            <th className="px-4 py-2 border">Date</th>
                            <th className="px-4 py-2 border">Time</th>
                            <th className="px-4 py-2 border">Work Mode</th>
                            <th className="px-4 py-2 border">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monthlyAttendance.map((record, index) => (
                            <tr key={index}>
                              <td className="px-4 py-2 border">{allUsers.find(u => u.id === record.userId)?.name}</td>
                              <td className="px-4 py-2 border">{record.date}</td>
                              <td className="px-4 py-2 border">{record.time}</td>
                              <td className="px-4 py-2 border">
                                {record.workMode === "onSite"
                                  ? `Latitude: ${record.latitude?.toFixed(6)}, Longitude: ${record.longitude?.toFixed(6)}`
                                  : "Work From Home"}
                              </td>
                              <td className="px-4 py-2 border">{record.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default UserAttendancePage;