import React, { useState, useEffect } from "react";
import { useAuth } from "../../Pages/AuthContext"; // Adjust the import path
import { collection, query, where, onSnapshot, doc, setDoc } from "firebase/firestore";
import { database } from "../Firebase/firebase";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "react-calendar/dist/Calendar.css";
import { format, startOfMonth, endOfMonth, getDaysInMonth, eachDayOfInterval, addMonths } from "date-fns";
import DashboardLayout from "../../layouts/dashboardlayout";
import Modal from "../../layouts/model"; // Import a modal component
import Skeleton from "react-loading-skeleton"; // Import a skeleton loading library
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton CSS

const UserAttendancePage = () => {
  const { user, userName, role } = useAuth(); // Use user, userName, and role from AuthContext
  const [attendance, setAttendance] = useState<{ userId: string; date: string; time: string; status: string; workMode: string; latitude?: number; longitude?: number }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date()); // State to store selected month (default to current month)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date()); // State to store selected date (default to today)
  const [currentMonthDates, setCurrentMonthDates] = useState<Date[]>([]); // State to store all dates of the selected month
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch all users
    const fetchAllUsers = async () => {
      setIsLoading(true); // Start loading
      try {
        const usersQuery = query(collection(database, "users"));
        const unsubscribe = onSnapshot(usersQuery, (querySnapshot) => {
          const usersData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAllUsers(usersData);
          setIsLoading(false); // Stop loading after data is fetched
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching users:", error);
        setIsLoading(false); // Stop loading in case of error
      }
    };

    fetchAllUsers();
  }, []);

  useEffect(() => {
    // Fetch attendance in real-time
    const fetchRealTimeAttendance = async () => {
      setIsLoading(true); // Start loading
      try {
        const q = query(collection(database, "attendance"));
        
        // Use `onSnapshot` for real-time updates
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const attendanceData = querySnapshot.docs.map((doc) => ({
            userId: doc.data().userId,
            date: doc.data().date,
            time: doc.data().time,
            status: doc.data().status,
            workMode: doc.data().workMode || "N/A", // Default to "N/A" if workMode is not set
            latitude: doc.data().latitude || null, // Include latitude
            longitude: doc.data().longitude || null, // Include longitude
          }));
          setAttendance(attendanceData);
          setIsLoading(false); // Stop loading after data is fetched
        });

        // Cleanup subscription on unmount
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching attendance:", error);
        setIsLoading(false); // Stop loading in case of error
      }
    };

    fetchRealTimeAttendance();
  }, []);

  useEffect(() => {
    // Generate all dates of the selected month
    const startDate = startOfMonth(selectedMonth);
    const endDate = endOfMonth(selectedMonth);
    const dates = eachDayOfInterval({ start: startDate, end: endDate });
    setCurrentMonthDates(dates);

    // Reset selected date to the first day of the month if the selected date is not in the current month
    if (selectedDate.getMonth() !== selectedMonth.getMonth()) {
      setSelectedDate(startDate);
    }
  }, [selectedMonth]);

  const handleMarkAttendance = async (userId: string, workMode: string) => {
    const today = format(new Date(), "yyyy-MM-dd");
    const time = format(new Date(), "hh:mm:ss a"); // 12-hour format
    const attendanceRef = doc(database, "attendance", `${userId}_${today}`);

    try {
      // Get the user's location if workMode is "onSite"
      let latitude: number | null = null;
      let longitude: number | null = null;

      if (workMode === "onSite") {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      }

      await setDoc(attendanceRef, {
        userId,
        date: today,
        time,
        status: workMode === "onSite" ? "present" : "workFromHome", // Set status based on work mode
        workMode, // Include work mode in the Firestore document
        latitude: workMode === "onSite" ? latitude : null, // Include latitude if on-site
        longitude: workMode === "onSite" ? longitude : null, // Include longitude if on-site
      });
      console.log("Attendance marked as present for:", userId);
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonthValue = new Date(event.target.value);
    setSelectedMonth(selectedMonthValue);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDateValue = new Date(event.target.value);
    setSelectedDate(selectedDateValue);
  };

  const getAttendanceForSelectedDate = (userId: string) => {
    const selectedDateString = format(selectedDate, "yyyy-MM-dd");
    const attendanceRecord = attendance.find((record) => record.userId === userId && record.date === selectedDateString);
    return attendanceRecord || null;
  };

  return (
    <DashboardLayout title="User Attendance" showBackButton>
      <div className="min-h-screen bg-gray-100 p-6">
        <h1 className="text-2xl font-bold mb-6">User Attendance</h1>

        {/* Month and Date Dropdowns */}
        <div className="mb-6 flex gap-4">
          {/* Month Dropdown */}
          <div className="flex-1">
            <label htmlFor="month-select" className="block text-sm font-medium text-gray-700">
              Select Month
            </label>
            <select
              id="month-select"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
              value={selectedMonth.toISOString()}
              onChange={handleMonthChange}
            >
              {Array.from({ length: 12 }, (_, i) => {
                const monthDate = addMonths(new Date(), i - new Date().getMonth());
                return (
                  <option key={i} value={monthDate.toISOString()}>
                    {format(monthDate, "MMMM yyyy")}
                  </option>
                );
              })}
            </select>
          </div>

          {/* Date Dropdown */}
          <div className="flex-1">
            <label htmlFor="date-select" className="block text-sm font-medium text-gray-700">
              Select Date
            </label>
            <select
              id="date-select"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
              value={selectedDate.toISOString()}
              onChange={handleDateChange}
            >
              {currentMonthDates.map((date, index) => (
                <option key={index} value={date.toISOString()}>
                  {format(date, "yyyy-MM-dd")}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Attendance Table */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Attendance Details</h2>
          {isLoading ? (
            <div className="text-center">Loading...</div> // Simple loading text
          ) : (
            <table className="w-full table-auto border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">Name</th>
                  <th className="px-4 py-2 border">Role</th>
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">Attendance</th>
                  <th className="px-4 py-2 border">Time Marked</th>
                  <th className="px-4 py-2 border">Work Mode</th>
                  <th className="px-4 py-2 border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map((user) => {
                  const attendanceRecord = getAttendanceForSelectedDate(user.id);
                  return (
                    <tr key={user.id}>
                      <td className="px-4 py-2 border">{user.name}</td>
                      <td className="px-4 py-2 border">{user.role}</td>
                      <td className="px-4 py-2 border">{format(selectedDate, "yyyy-MM-dd")}</td>
                      <td
                        className={`px-4 py-2 border ${
                          attendanceRecord?.status === "present" ? "text-green-500" : "text-red-500"
                        }`}
                      >
                        {attendanceRecord?.status === "present" ? "Present" : "Absent"}
                      </td>
                      <td className="px-4 py-2 border">{attendanceRecord?.time || "N/A"}</td>
                      <td className="px-4 py-2 border">
                        {attendanceRecord?.workMode === "onSite"
                          ? `On Site (Lat: ${attendanceRecord.latitude?.toFixed(6)}, Long: ${attendanceRecord.longitude?.toFixed(6)})`
                          : attendanceRecord?.workMode || "N/A"}
                      </td>
                      <td className="px-4 py-2 border">
                        <button
                          onClick={() => handleMarkAttendance(user.id, "onSite")} // Default to "onSite"
                          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                          disabled={attendanceRecord?.status === "present"}
                        >
                          Mark Attendance
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        {/* View All Attendance Button */}
        <div className="mb-6 flex justify-end">
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            View Attendance Calendar
          </button>
        </div>

        {/* Attendance Calendar Popup */}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl h-[90vh] flex flex-col">
              <div className="p-6 border-b">
                <h2 className="text-xl font-bold mb-4">Attendance Calendar</h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                  &times;
                </button>
              </div>
              <div className="p-6 overflow-y-auto flex-1">
                <Calendar
                  onChange={(value) => setSelectedMonth(value as Date)}
                  value={selectedMonth}
                  view="year"
                  onClickMonth={(value) => setSelectedMonth(value)}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default UserAttendancePage;