import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import { FaPlus, FaEdit, FaTrash, FaLock, FaUnlock } from "react-icons/fa"; // Import icons
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore"; // Firestore imports
import { getFirestore } from "firebase/firestore";
import firebaseApp from "../Firebase/firebase"; // Adjust the path to your Firebase config
import DashboardLayout from "../../layouts/dashboardlayout/index"; // Import the DashboardLayout
import Modal from "../../layouts/model"; // Import a modal component for forms

const db = getFirestore(firebaseApp);

interface User {
  id: string;
  name: string;
  email: string;
  contactNumber: string;
  role: string;
  isRestricted?: boolean; // Add a field for restricted status
}

const UsersManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [editName, setEditName] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editContactNumber, setEditContactNumber] = useState("");
  const [editRole, setEditRole] = useState("");
  const navigate = useNavigate();

  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "users"); // Replace "users" with your Firestore collection name
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as User[];
      setUsers(usersList);
      setFilteredUsers(usersList); // Initialize filtered users with all users
    };

    fetchUsers();
  }, []);

  // Handle role filter change
  useEffect(() => {
    if (selectedRole === "all") {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter((user) => user.role === selectedRole);
      setFilteredUsers(filtered);
    }
  }, [selectedRole, users]);

  // Handle Create User button click
  const handleCreateUser = () => {
    navigate("/adminsignup"); // Redirect to admin signup page
  };

  // Handle Delete User
  const handleDeleteUser = async (userId: string) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      await deleteDoc(doc(db, "users", userId));
      setUsers(users.filter((user) => user.id !== userId));
      setFilteredUsers(filteredUsers.filter((user) => user.id !== userId));
    }
  };

  // Handle Restrict/Unrestrict User
  const handleRestrictUser = async (userId: string, isRestricted: boolean) => {
    await updateDoc(doc(db, "users", userId), { isRestricted: !isRestricted });
    const updatedUsers = users.map((user) =>
      user.id === userId ? { ...user, isRestricted: !isRestricted } : user
    );
    setUsers(updatedUsers);
    setFilteredUsers(updatedUsers);
  };

  // Open modal for editing user details
  const openEditModal = (user: User) => {
    setSelectedUser(user);
    setEditName(user.name);
    setEditEmail(user.email);
    setEditContactNumber(user.contactNumber);
    setEditRole(user.role);
    setIsModalOpen(true);
  };

  // Handle Save Changes
  const handleSaveChanges = async () => {
    if (selectedUser) {
      await updateDoc(doc(db, "users", selectedUser.id), {
        name: editName,
        email: editEmail,
        contactNumber: editContactNumber,
        role: editRole,
      });
      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id
          ? {
              ...user,
              name: editName,
              email: editEmail,
              contactNumber: editContactNumber,
              role: editRole,
            }
          : user
      );
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      setIsModalOpen(false); // Close the modal
    }
  };

  return (
    <DashboardLayout title="Users Management" showBackButton>
      {/* Create User Button */}
      <div className="flex justify-end mb-6">
        <button
          onClick={handleCreateUser}
          className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
        >
          <FaPlus className="mr-2" />
          Create User
        </button>
      </div>

      {/* Role Filter Dropdown */}
      <div className="mb-6">
        <label htmlFor="role" className="block text-sm font-medium text-gray-700">
          Filter by Role
        </label>
        <select
          id="role"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="all">All Roles</option>
          <option value="admin">Admin</option>
          <option value="user">User</option>
          <option value="finance">Finance</option>
        </select>
      </div>

      {/* Users Table */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Contact Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Role
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {user.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {user.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {user.contactNumber}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {user.role}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  <button
                    onClick={() => openEditModal(user)}
                    className="text-blue-500 hover:text-blue-700 mr-2"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleDeleteUser(user.id)}
                    className="text-red-500 hover:text-red-700 mr-2"
                  >
                    <FaTrash />
                  </button>
                  <button
                    onClick={() => handleRestrictUser(user.id, user.isRestricted || false)}
                    className="text-yellow-500 hover:text-yellow-700"
                  >
                    {user.isRestricted ? <FaUnlock /> : <FaLock />}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Editing User Details */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-xl font-bold mb-4">Edit User Details</h2>
        <div className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={editEmail}
              onChange={(e) => setEditEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700">
              Contact Number
            </label>
            <input
              type="text"
              id="contactNumber"
              value={editContactNumber}
              onChange={(e) => setEditContactNumber(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <select
              id="role"
              value={editRole}
              onChange={(e) => setEditRole(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="admin">Admin</option>
              <option value="user">User</option>
              <option value="finance">Finance</option>
            </select>
          </div>
          <button
            onClick={handleSaveChanges}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Save Changes
          </button>
        </div>
      </Modal>
    </DashboardLayout>
  );
};

export default UsersManagement;